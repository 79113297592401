import React, { useEffect, useState, useRef } from 'react'
import Wrapper from '../../hoc/Wrapper/Wrapper'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import TextBox from '../../controls/TextBox/TextBox';

import BoolSelector from '../SettingControls/BoolSelector'
function ModalSocialNetworks({item,close,save}) {
    const [showElement, setShowElement] = useState(item.isVisible);
    const [itemElement, setItemElement] = useState({...item});
    const onShowElement=(val)=>{
        setShowElement(val);
        item.isVisible=val;
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        item[name]= value;
        setItemElement({
            ...itemElement,
            [name]: value,
        });
    };
    useEffect(()=>{
        item=itemElement;
    },[itemElement])
  return (
<Wrapper>
<div class="modal show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">ویرایش {item.name}</h5>
          <button type="button" class="close" data-dismiss="modal" onClick={(event)=>close(event,itemElement)} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
        <BoolSelector title="قیمت نمایش داده شود؟" click={onShowElement} myState={showElement} />
        <TextBox title="لینک" name="link" value={itemElement.link} changed={handleChange} />

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" onClick={(event)=>save(event,itemElement)} >ذخیره</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={(event)=>close(event,item)} >بستن</button>
        </div>
      </div>
    </div>
    </div>
    <div className='modal-backdrop show'></div>
</Wrapper>
  )
}

export default ModalSocialNetworks