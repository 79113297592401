import React from 'react'
import Wrapper from '../../hoc/Wrapper/Wrapper'

function Modal(props) {
    
    return (
        <Wrapper>

            <div className={"modal " + (props.isShow?"show":"")} id="modaldemo1" aria-modal="true" style={{ paddingRight: "17px", display: (props.isShow?"block":"none") }}>
                <div className="modal-dialog  modal-dialog-scrollable" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">{props.title}</h6><button aria-label="بستن" onClick={props.close} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                          
                        {props.children}  
                          
                        </div>
                        <div className="modal-footer">
                            {props.footer}

                            <button className="btn ripple btn-primary" type="button" onClick={props.saveClick}>ذخیره تغییرات</button>
                            <button className="btn ripple btn-secondary" data-dismiss="modal" type="button" onClick={props.close}>بستن</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal-backdrop " + (props.isShow?"show":"")} style={{ display: (props.isShow?"":"none") }}></div>
        </Wrapper>
    )
}

export default Modal