import React, { useEffect, useState, useRef, useCallback } from 'react'
import PageTitle from '../../../../components/template/PageTitle/PageTitle'
import PageWrapper from '../../../../components/template/PageWrapper/PageWrapper'
import PageCard from '../../../../controls/PageCard/PageCard'
import Row from '../../../../controls/Row/Row'
import Col from '../../../../controls/Col/Col'
import * as appConsts from '../../../../store/consts'
import API from '../../../../modules/api'
import AttachSelector from '../../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../../../components/SettingControls/TextEditor'
import TextEditLink from '../../../../components/SettingControls/TextEditLink'
import './SiteHeader.css'
function SiteHeader() {
  const [filterModal, setFilterModal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageState, setSelectedImage] = useState(null);
  const [homeState, setHomeState] = useState(
    {
      logo_url: "./assets/front-images/Logo.png",
      menu_name: "main",
      menu_name_edit: false,
      menu_items: [],

    });





  const imageDoubleClick = (event) => {
    event.preventDefault();
    setFilterModal(["image"])
    setShowModal(true)
    setSelectedImage(event.target);
  }
  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false)
  }
  const selectedImage = (event, item) => {
    event.preventDefault();
    if (selectedImageState != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-header-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
        setHomeState({
          ...homeState,
          [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
        })
      })

      setShowModal(false)
    }

  }
  const selectedText = (event, name) => {
    event.preventDefault();

    setHomeState({ ...homeState, [`${name}_edit`]: true })
    console.log(homeState);
  }
  const closeText = (event, name) => {
    event.preventDefault();

    setHomeState({ ...homeState, [`${name}_edit`]: false })
    console.log(homeState);
  }
  const saveText = (event, cntrl) => {
    event.preventDefault();
    console.log(cntrl)
    if (cntrl != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-header-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
        setHomeState({ ...homeState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
      })

      setShowModal(false)
    }

  }



  
  useEffect(() => {
    loadData();



}, [])

useEffect(() => {
    loadMenus({ ...homeState });

}, [homeState.menu_name])
const loadMenus = (obj) => {
    let arrNames = [obj.menu_name];
    (new API()).sendPostWithoutToken(`/api/links/names`, { names: arrNames }, (result) => {
        console.log("MNU", result)
        if (result[obj.menu_name] != null) {
          obj[`menu_items`] = result[obj.menu_name];
      } else {
          obj[`menu_items`] = [];
      }

        setHomeState({ ...homeState, ...obj });

    });
}



const loadData = () => {

    let arrNames = [];
    let arrImges = ["home-header-logo-attach-id"];
    let arrTexts = [];
    let arrStatics = ['home-header-menu_name'];
    arrNames = [...arrNames, ...arrImges];
    arrNames = [...arrNames, ...arrStatics];
  
   
    let obj = { ...homeState };
    try {

        (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
            console.log("TRE", result)

            for (let index = 0; index < arrImges.length; index++) {
                const element = arrImges[index];
                const elementName = element.replace('home-header-', '').replace('-attach-id');
                if (result[element] != null)
                    obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

            }
            for (let index = 0; index < arrTexts.length; index++) {
                const element = arrTexts[index];
                const elementName = element.replace('home-header-', '');
                if (result[element] != null)
                    obj[elementName] = result[element];

            }
            for (let index = 0; index < arrStatics.length; index++) {

                const element = arrStatics[index];

                const elementName = element.replace('home-header-', '');
                if (result[element] != null)
                    obj[elementName] = result[element];

            }
        
           
    
            loadMenus(obj);
            //   setHomeState({ ...homeState, ...obj });
        });

    } catch (error) {

        console.log("ERR", error)
    }



}


  return (
    <PageWrapper>
      <PageTitle title="تنظیمات هدر" />
      <Row>

        <Col size="lg-12">

          <PageCard>
            <div className='top_home_wraper'>
              <div className="container"><nav className="navbar navbar-expand-lg">
                <a className="navbar-brand" href="javascript:;">
                  <img id="logo" onDoubleClick={(event) => imageDoubleClick(event)} src={homeState.logo_url} alt="image" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"><span className="toggle-wrap">
                    <span className="toggle-bar"></span></span></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav ml-auto">
                    <li>
                    {
                                                                !homeState[`menu_name_edit`] ? <span  ><TextEditLink name={`menu_name`} selectedText={selectedText} /></span> :
                                                                    <span>
                                                                        <TextEditor name={`menu_name`} myState={homeState} close={closeText} save={saveText} />

                                                                    </span>
                                                            }
                    </li>
                    {
                      homeState.menu_items.map((item) => {
                        return <li className="nav-item">
                          <a href="#" className="nav-link">{item.title}</a>
                        </li>
                      })
                    }



                  </ul>
                </div>
              </nav>
              </div>
              <Row>

                <Col size="lg-6">





                </Col>
                <Col size="lg-6">

                </Col>
              </Row>
            </div>
          </PageCard>
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default SiteHeader