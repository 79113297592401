import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import Table from '../../controls/Table/Table'
import THead from '../../controls/Table/THead'
import TicketList from '../../components/Tickets/TicketList'
import Modal from '../../controls/Modal/Modal'
import EditGroup from '../../components/Tickets/EditGroup'
import AddGroup from '../../components/Tickets/AddGroup'
import Swal from 'sweetalert2';
import Utils from '../../modules/utils';

import { useNavigate } from "react-router-dom";
import TicketFilter from '../../components/Tickets/TicketFilter'

function Tickets() {
    const util = new Utils();

    const [ticketsState, setTicketsState] = useState([])
    const [ticketsFilterState, setTicketsFilterState] = useState([])
    const [filterState, setFilterState] = useState("all")

    const navigate = useNavigate();


    useEffect(() => {

        (new API()).sendGETWithToken("/api/tickets/root", (result) => {
            setTicketsState(result);
            setTicketsFilterState([...result]);
        })

    }, [])

 


    const onDeleteClick = (event, item) => {
        util.showDeleteMessage(() => {


            (new API()).sendPostWithToken(`/api/tickets/groups/delete/${item.id}`, {}, (result) => {
                // console.log(result);
                // setSelectedAction(null)
                // setSelectedGroup(null)
                // setShowModal(false)
                // console.clear()
                // setTicketsState(result);
                // util.showAlert("تائید!", "اطلاعات تغییر یافت");
            })



        });



    }
    useEffect(()=>{
       // setTicketsFilterState([]);
       
       loadFilterData();

    },[filterState])
    const loadFilterData=()=>{
        switch (filterState) {
            case "all":
                setTicketsFilterState([...ticketsState]);
                break;
        
            default:
            const temp = ticketsState.filter((item)=>item.status==parseInt(filterState))
           

            setTicketsFilterState([...temp]);
                break;
        }
    }
   
  
    const TicketItemClicked = (event, item, action) => {
        event.preventDefault();
       
        switch (action) {
            case "view":
                navigate(`/ticket/${item.id}`)
                break;
            case "close":
               // onDeleteClick(event, item)
                break;
         

            default:
                break;
        }
    }
    const handleFilterClick=(filterText)=>{
        setFilterState(filterText);
    }
    return (
        <PageWrapper>
            <PageTitle title="تیکتها" />
            <Row>
                <Col size="lg-12">

                    <PageCard>
                    <TicketFilter filterStatus={filterState} click={handleFilterClick}/>

                        <Table>
                            <THead>
                                <th className='w-25'>#</th>
                                <th>گروه</th>
                                <th>کاربر</th>
                                <th>عنوان</th>
                                <th>تاریخ</th>
                                <th>وضعیت</th>

                            </THead>
                            <tbody>
                                {

                                    ticketsFilterState.map((item) => {
                                        return <TicketList item={item} click={TicketItemClicked} />

                                    })

                                }
                            </tbody>
                        </Table>
                       
                    </PageCard>
                </Col>
            </Row>

        </PageWrapper>

    )
}

export default Tickets