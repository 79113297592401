import React from 'react'
import Utils from '../../modules/utils'
import { Directions } from '../../store/consts';

function LinkList({ item,click }) {
  return (
    <tr key={item.id}>
      <td>

        <div class="btn-icon-list">
          <button class="btn ripple btn-primary btn-icon" onClick={(event)=>click(event,item,"add_child")}><i class="fe fe-plus"></i></button>
          <button class="btn ripple btn-primary btn-icon" onClick={(event)=>click(event,item,"edit")}><i class="fe fe-edit"></i></button>
          <button class="btn ripple btn-secondary btn-icon" onClick={(event)=>click(event,item,"delete")}><i class="fe fe-trash"></i></button>
         
        </div>

      </td>
      <td>{item.id}</td>
      <td>{item.title}</td>
      <td>{item.group_name}</td>
      <td>{item.ord}</td>
      <td>{(item.parent!=null?item.parent.title:"")}</td>
      <td>{item.href}</td>
     

    </tr>

  )
}

export default LinkList