import React, { useEffect, useState } from 'react'
import MenuItem from './MenuItem'
import { useNavigate } from "react-router-dom";
import { connect,useDispatch } from 'react-redux'

import {addChangeLogin} from '../../../store/projectActions'
function SideBar() {
    const navigate = useNavigate();
    const [menuActiveState, setMenuActiveState] = useState(window.location.pathname);
    const dispatch = useDispatch();
    const [menuState, setMenuState] = useState([
        { title: "مدیر سیستم", url: "-", icon: "", isHeader: true, childs: [] },
        { title: "فایلها", url: "/attachments", icon: "fa fa-file", isHeader: false, childs: [] },
        { title: "محصولات", url: "/products", icon: "ion ion-help", isHeader: false, childs: [] },
        {
            title: "سایت", url: "/site", icon: "ion ion-navigate", isHeader: false, childs: [
            { title: "تنظیمات صفحه اصلی", url: "/home-site", icon: "fa fa-image", isHeader: false,parent:"/site", childs: [] },
            { title: "تنظیمات فوتر", url: "/site-footer", icon: "fa fa-image", isHeader: false,parent:"/site", childs: [] },
            { title: "تنظیمات هدر", url: "/site-header", icon: "fa fa-image", isHeader: false,parent:"/site", childs: [] },
            { title: "لینکهای سایت", url: "/links", icon: "fa fa-lang", isHeader: false,parent:"/site", childs: [] },
            ]
        },
        {
            title: "تیکتها", url: "/tickets", icon: "ion ion-navigate", isHeader: false, childs: [
                { title: "گروهها", url: "/tickets/groups", icon: "fa fa-lang", isHeader: false,parent:"/tickets", childs: [] },
                { title: "تیکتها", url: "/tickets", icon: "fa fa-users", isHeader: false,parent:"/tickets", childs: [] },
            ]
        },
        {
            title: "سیستم", url: "/system", icon: "ion ion-navigate", isHeader: false, childs: [
                { title: "زبانها", url: "/langs", icon: "fa fa-lang", isHeader: false,parent:"/system", childs: [] },
                { title: "کاربران", url: "/users", icon: "fa fa-users", isHeader: false,parent:"/system", childs: [] },
            ]
        },

        //{title:"",url:"",icon:"",isHeader:falsک e,childs:[]},
    ])
    useEffect(()=>{

    

        setMenuActiveState(window.location.pathname)

    },[])

    const onExitClicked=(event)=>{
        event.preventDefault();
        localStorage.removeItem("token");
        navigate("/")
        dispatch({ type: 'CHANGELOGIN',value:false })

    }
    const MenuItemClicked=(event,item)=>{
        event.preventDefault();
        if(item.parent)
        setMenuActiveState(item.parent)
    else
        setMenuActiveState(item.url)
        navigate(item.url)
    }
    return (
        <div class="main-sidebar main-sidebar-sticky side-menu">
            <div class="sidemenu-logo">
                <a class="main-logo" href="index.html">
                    <img src="/assets/img/brand/logo-light.png" class="header-brand-img desktop-logo" alt="لوگو" />
                    <img src="/assets/img/brand/icon-light.png" class="header-brand-img icon-logo" alt="لوگو" />
                    <img src="/assets/img/brand/logo.png" class="header-brand-img desktop-logo theme-logo" alt="لوگو" />
                    <img src="/assets/img/brand/icon.png" class="header-brand-img icon-logo theme-logo" alt="لوگو" />
                </a>
            </div>
            <div class="main-sidebar-body">
                <ul class="nav">
                    {
                        menuState.map((item) => {
                            return <MenuItem item={item} clicked={MenuItemClicked} active={menuActiveState}/>
                        }
                        )

                    }
  <li className="nav-item">
                    <a className="nav-link" href="" onClick={(event) => onExitClicked(event)}><span className="shape1"></span><span className="shape2"></span><i className="fe fe-x-circle ml-2"></i><span className="sidemenu-label">خروج</span></a>
                </li>
                </ul>
            </div>
        </div>
    )
}

export default SideBar