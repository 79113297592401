import React, { useEffect, useState } from 'react'

import Utils from '../../modules/utils';

function TicketViewCard({ item }) {


    const util = new Utils()

    const [bgListState,setBgListState]=useState(["bg-primary tx-white","bg-secondary tx-white","bg-success tx-white",""])
    const [bgState,setBgState]=useState(bgListState[Math.floor(Math.random() * bgListState.length)])
    
    useEffect(()=>{


    },[])
    return (
        <div className={`card custom-card ${bgState}`}>
            <div className={`card-header custom-card-header border-bottom-0 ${bgState}`}>
                <h5 className="main-content-label tx-dark my-auto tx-medium mb-0">      {item.title}</h5>
                <div className="card-options">
                    <span><i className='fe fe-user'></i> {item.first_name} {item.last_name}</span>
                    <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a>
                </div>
            </div>
            <div className="card-body">
                {item.message}

            </div>
            <div className="card-footer">

                {util.toPersianDateTime(new Date(item.create_at))}
            </div>
        </div>

    )
}

export default TicketViewCard