import React, { useEffect, useState, useRef } from 'react'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import './HomeAnalyze.css'

import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../SettingControls/TextEditor'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import TextEditLink from '../../SettingControls/TextEditLink'
import BoolSelector from '../../SettingControls/BoolSelector'
function HomeAnalyze() {
  const [showElement, setShowElement] = useState(true);
  const [filterModal, setFilterModal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageState, setSelectedImage] = useState(null)
  const [homeAnalyzeState, setHomeAnalyzeState] = useState(
    {
      image1_url: "./assets/front-images/analyze/analyze-data-01.png",
      image2_url: "./assets/front-images/analyze/analyze-data-02.png",
      image3_url: "./assets/front-images/analyze/analyze-data-03.png",


      title1: "Analyze your data with powerful tool",
      title1_edit: false,
      dsc1: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
      dsc1_edit: false,
      title2: "Carefully designed",
      title2_edit: false,
      dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has",
      dsc2_edit: false,
      title3: "Seamless Sync",
      title3_edit: false,
      dsc3: "Simply dummy text of the printing and typesetting inustry lorem Ipsum has Lorem dollar summit.",
      dsc3_edit: false,


    }
  );




  useEffect(() => {
    loadData();



  }, [])


  const loadData = () => {

    let arrNames = ['home-analyze-show'];
    let arrImges = [];
    let arrTexts = [];

    for (let index = 1; index <= 3; index++) {
      arrNames = [...arrNames, `home-analyze-image${index}-attach-id`];
      arrImges = [...arrImges, `home-analyze-image${index}-attach-id`];
      const arr = ['title', 'dsc'];
      for (let index2 = 0; index2 < arr.length; index2++) {
        arrNames = [...arrNames, `home-analyze-${arr[index2]}${index}`];
        arrTexts = [...arrTexts, `home-analyze-${arr[index2]}${index}`]

      }

    }
    let obj = {};
    try {

      (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
        console.log("TRE", result)
        if (result['home-analyze-show'] != null) {
          setShowElement((result['home-analyze-show'] == 'true'))
        }
        for (let index = 0; index < arrImges.length; index++) {
          const element = arrImges[index];
          const elementName = element.replace('home-analyze-', '').replace('-attach-id');
          if (result[element] != null)
            obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

        }
        for (let index = 0; index < arrTexts.length; index++) {
          const element = arrTexts[index];
          const elementName = element.replace('home-analyze-', '');
          if (result[element] != null)
            obj[elementName] = result[element];

        }


        setHomeAnalyzeState({ ...homeAnalyzeState, ...obj });
      });

    } catch (error) {

    }



  }


  const imageDoubleClick = (event) => {
    event.preventDefault();
    setFilterModal(["image"])
    setShowModal(true)
    setSelectedImage(event.target);
  }
  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false)
  }
  const selectedImage = (event, item) => {
    event.preventDefault();
    if (selectedImageState != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-analyze-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
        setHomeAnalyzeState({
          ...homeAnalyzeState,
          [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
        })
      })

      setShowModal(false)
    }

  }
  const selectedText = (event, name) => {
    event.preventDefault();

    setHomeAnalyzeState({ ...homeAnalyzeState, [`${name}_edit`]: true })
    console.log(homeAnalyzeState);
  }
  const closeText = (event, name) => {
    event.preventDefault();

    setHomeAnalyzeState({ ...homeAnalyzeState, [`${name}_edit`]: false })
    console.log(homeAnalyzeState);
  }
  const saveText = (event, cntrl) => {
    event.preventDefault();
    console.log(cntrl)
    if (cntrl != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-analyze-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
        setHomeAnalyzeState({ ...homeAnalyzeState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
      })

      setShowModal(false)
    }

  }
  const onShowElementClicked = (val) => {

    setShowElement(val);

  }
  useEffect(() => {

    (new API()).sendPostWithToken("/api/settings", { name: `home-analyze-show`, val: `${showElement}` }, (result) => {

    })
  }, [showElement])



  return (
   
      <Row>

        <Col size="lg-12">

          <PageCard>
          <BoolSelector title="نمایش داده شود؟" click={onShowElementClicked} myState={showElement}/>
           
            <section class="row_am analyze_section">
              <div class="container">
                <div class="row">
                  <div class="col-md-6">
                    <div class="analyze_text aos-init aos-animate" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                      <span class="icon">
                        <img src="./assets/front-images/analyze/Analyze_Icon.svg" alt="image" />
                      </span>
                      <div class="section_title">
                        <h2>
                          {
                            !homeAnalyzeState.title1_edit ? <span  ><TextEditLink name="title1" selectedText={selectedText} />{homeAnalyzeState.title1}</span> :
                              <span>
                                <TextEditor name="title1" myState={homeAnalyzeState} close={closeText} save={saveText} />

                              </span>
                          }

                        </h2>
                        <p> 
                        {
                            !homeAnalyzeState.dsc1_edit ? <span  ><TextEditLink name="dsc1" selectedText={selectedText} />{homeAnalyzeState.dsc1}</span> :
                              <span>
                                <TextEditor name="dsc1" type='textarea' myState={homeAnalyzeState} close={closeText} save={saveText} />

                              </span>
                          }
                        </p>
                      </div>
                      <ul>
                      {
                        [...Array(2)].map((x, i) => {
                          return <li data-aos="fade-up" data-aos-duration="2000" class="aos-init">
                          <h3>
                          {
                            !homeAnalyzeState[`title${i+2}_edit`] ? <span  ><TextEditLink name={`title${i+2}`} selectedText={selectedText} />{homeAnalyzeState[`title${i+2}`]}</span> :
                              <span>
                                <TextEditor name={`title${i+2}`} myState={homeAnalyzeState} close={closeText} save={saveText} />

                              </span>
                          }
                          </h3>
                          <p>
                          {
                            !homeAnalyzeState[`dsc${i+2}_edit`] ? <span  ><TextEditLink name={`dsc${i+2}`} selectedText={selectedText} />{homeAnalyzeState[`dsc${i+2}`]}</span> :
                              <span>
                                <TextEditor name={`dsc${i+2}`} type='textarea' myState={homeAnalyzeState} close={closeText} save={saveText} />

                              </span>
                          }
                          </p>
                        </li>

                        })

                      }
                        
                    
                       
                       </ul>
                       </div>
                       </div>
                  <div class="col-md-6">
                    <div class="analyze_image aos-init aos-animate" data-aos="fade-in" data-aos-duration="1000">

                      {
                        [...Array(3)].map((x, i) => {
                          return <img id={`image${i + 1}`} onDoubleClick={(event) => imageDoubleClick(event)} data-aos-duration="2000" data-aos-delay="100" class="moving_animation aos-init" src={homeAnalyzeState[`image${i + 1}_url`]} alt="image" />

                        })

                      }

                    </div>
                  </div>
                </div></div></section>

          </PageCard>
        </Col>
        {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}
      </Row>

   
  )
}

export default HomeAnalyze