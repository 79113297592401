import React, { useEffect, useState, useRef } from 'react'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import './Collaborate.css'

import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../SettingControls/TextEditor'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import TextEditLink from '../../SettingControls/TextEditLink'
import BoolSelector from '../../SettingControls/BoolSelector'

function HomeCollaborate() {
  const [showElement, setShowElement] = useState(true);
  const [filterModal, setFilterModal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageState, setSelectedImage] = useState(null)
  const [homeCollaborateState, setHomeCollaborateState] = useState(
    {
      icon1_url: "./assets/front-images/collaborate/Collaborate-icon_1.png",
      image1_url: "./assets/front-images/collaborate/Collaborate-01.png",
      icon2_url: "./assets/front-images/collaborate/Collaborate-icon_2.png",
      image2_url: "./assets/front-images/collaborate/Collaborate-02.png",
      image_secure_url: "./assets/front-images/collaborate/securely.svg",


      title: "Collaborate securely everything",
      title_edit: false,
      dsc: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
      dsc_edit: false,



      title1: "Sync followers",
      title1_edit: false,
      dsc1: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
      dsc1_edit: false,
      title2: "Globally connected",
      title2_edit: false,
      dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has",
      dsc2_edit: false,


      link_title: "READ MORE",
      link_title_edit: false,

      link_url: "/",
      link_url_edit: false,


    }
  );




  useEffect(() => {
    loadData();



  }, [])


  const loadData = () => {

    let arrNames = ['home-collaborate-show'];
    let arrImges = [];
    let arrTexts = [];
    let arrStatics = ["home-collaborate-link_title","home-collaborate-link_url",'home-collaborate-title', 'home-collaborate-dsc'];
    arrNames=[...arrNames,...arrStatics];
    for (let index = 1; index <= 2; index++) {
      arrNames = [...arrNames, `home-collaborate-icon${index}-attach-id`];
      arrNames = [...arrNames, `home-collaborate-image${index}-attach-id`];
      arrImges = [...arrImges, `home-collaborate-image${index}-attach-id`];
      arrImges = [...arrImges, `home-collaborate-icon${index}-attach-id`];
      const arr = ['title', 'dsc'];
      for (let index2 = 0; index2 < arr.length; index2++) {
        arrNames = [...arrNames, `home-collaborate-${arr[index2]}${index}`];
        arrTexts = [...arrTexts, `home-collaborate-${arr[index2]}${index}`]

      }

      arrNames = [...arrNames, `home-collaborate-image-secure-attach-id`];
      arrImges = [...arrImges, `home-collaborate-image-secure-attach-id`];

    }
    let obj = {};
    try {

      (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
        console.log("TRE", result)
        if (result['home-collaborate-show'] != null) {
          setShowElement((result['home-collaborate-show'] == 'true'))
        }
        for (let index = 0; index < arrImges.length; index++) {
          const element = arrImges[index];
          if(element=="home-collaborate-image-secure-attach-id"){
            if (result[element] != null)
              obj['image_secure_url'] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
          }else{
            const elementName = element.replace('home-collaborate-', '').replace('-attach-id');
            if (result[element] != null)
              obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;
          }
        

        }
        for (let index = 0; index < arrTexts.length; index++) {
          const element = arrTexts[index];
          const elementName = element.replace('home-collaborate-', '');
          if (result[element] != null)
            obj[elementName] = result[element];

        }
        for (let index = 0; index < arrStatics.length; index++) {
         
          const element = arrStatics[index];
        
          const elementName = element.replace('home-collaborate-', '');
          if (result[element] != null)
            obj[elementName] = result[element];

        }
console.log("WERTY",obj )
        setHomeCollaborateState({ ...homeCollaborateState, ...obj });
      });

    } catch (error) {

console.log("ERR",error)
    }



  }


  const imageDoubleClick = (event) => {
    event.preventDefault();
    setFilterModal(["image"])
    setShowModal(true)
    setSelectedImage(event.target);
  }
  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false)
  }
  const selectedImage = (event, item) => {
    event.preventDefault();
    if (selectedImageState != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-collaborate-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
        setHomeCollaborateState({
          ...homeCollaborateState,
          [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
        })
      })

      setShowModal(false)
    }

  }
  const selectedText = (event, name) => {
    event.preventDefault();

    setHomeCollaborateState({ ...homeCollaborateState, [`${name}_edit`]: true })
    console.log(homeCollaborateState);
  }
  const closeText = (event, name) => {
    event.preventDefault();

    setHomeCollaborateState({ ...homeCollaborateState, [`${name}_edit`]: false })
    console.log(homeCollaborateState);
  }
  const saveText = (event, cntrl) => {
    event.preventDefault();
    console.log(cntrl)
    if (cntrl != null) {

      (new API()).sendPostWithToken("/api/settings", { name: `home-collaborate-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
        setHomeCollaborateState({ ...homeCollaborateState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
      })

      setShowModal(false)
    }

  }
  const onShowElementClicked = (val) => {

    setShowElement(val);

  }
  useEffect(() => {

    (new API()).sendPostWithToken("/api/settings", { name: `home-collaborate-show`, val: `${showElement}` }, (result) => {

    })
  }, [showElement])


  return (
   
      <Row>

        <Col size="lg-12">

          <PageCard>
          <BoolSelector title="نمایش داده شود؟" click={onShowElementClicked} myState={showElement}/>
            <section className="row_am collaborate_section">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="collaborate_image aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">

                      {
                        [...Array(2)].map((x, i) => {
                          return <div className="img_block aos-init" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                            <img className="icon_img moving_animation" id={`icon${i + 1}`} onDoubleClick={(event) => imageDoubleClick(event)} src={homeCollaborateState[`icon${i + 1}_url`]} alt="image" />
                            <img id={`image${i + 1}`} onDoubleClick={(event) => imageDoubleClick(event)} src={homeCollaborateState[`image${i + 1}_url`]} alt="image" />
                          </div>

                        })

                      }



                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="collaborate_text aos-init pb-5" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="100">
                      <span className="icon"><img src={homeCollaborateState.image_secure_url} alt="image" /></span>
                      <div className="section_title">
                        <h2>
                          {
                            !homeCollaborateState.title_edit ? <span  ><TextEditLink name="title" selectedText={selectedText} />{homeCollaborateState.title}</span> :
                              <span>
                                <TextEditor name="title" myState={homeCollaborateState} close={closeText} save={saveText} />

                              </span>
                          }
                        </h2>
                        <p>{
                          !homeCollaborateState.dsc_edit ? <span  ><TextEditLink name="dsc" selectedText={selectedText} />{homeCollaborateState.dsc}</span> :
                            <span>
                              <TextEditor type='textarea' name="dsc" myState={homeCollaborateState} close={closeText} save={saveText} />

                            </span>
                        }</p>
                      </div>
                      <ul>

                        {
                          [...Array(2)].map((x, i) => {
                            return <li data-aos="fade-up" data-aos-duration="2000" className="aos-init">
                              <h3>  {
                                !homeCollaborateState[`title${i + 1}_edit`] ? <span  ><TextEditLink name={`title${i + 1}`} selectedText={selectedText} />{homeCollaborateState[`title${i + 1}`]}</span> :
                                  <span>
                                    <TextEditor name={`title${i + 1}`} myState={homeCollaborateState} close={closeText} save={saveText} />

                                  </span>
                              }
                              </h3>
                              <p>


                                {
                                  !homeCollaborateState[`dsc${i + 1}_edit`] ? <span  ><TextEditLink name={`dsc${i + 1}`} selectedText={selectedText} />{homeCollaborateState[`dsc${i + 1}`]}</span> :
                                    <span>
                                      <TextEditor type='textarea' name={`dsc${i + 1}`} myState={homeCollaborateState} close={closeText} save={saveText} />

                                    </span>
                                }
                              </p>
                            </li>

                          })

                        }



                      </ul>


                      <a href={homeCollaborateState.link_url} data-aos="fade-up" data-aos-duration="2000" className="btn btn_main aos-init">


                        {homeCollaborateState.link_title}  <i className="icofont-arrow-right"></i>




                      </a>


                      <div className='d-flex'>



                        {
                          !homeCollaborateState.link_url_edit ? <span  ><TextEditLink name="link_url" selectedText={selectedText} /></span> :
                            <span>
                              <TextEditor name="link_url" myState={homeCollaborateState} close={closeText} save={saveText} />

                            </span>
                        }

                        {
                          !homeCollaborateState.link_title_edit ? <span  ><TextEditLink name="link_title" selectedText={selectedText} /></span> :
                            <span>
                              <TextEditor name="link_title" myState={homeCollaborateState} close={closeText} save={saveText} />

                            </span>
                        }

                      </div>


                      <br />

                    </div>


                  </div>
                </div>

              </div>
            </section>
          </PageCard>
        </Col>
        {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}
      </Row>


  )
}

export default HomeCollaborate