import React, { useState } from 'react'

function TicketFilter({ filterStatus = "all", click }) {
    const [filterStates, setFilterStates] = useState([
        { label: "همه موارد", value: "all" },
        { label: "ارسال شده", value: "0" },
        { label: "پاسخ داده شده", value: "1" },
        { label: "بسته شده", value: "2" },
    ]);
    return (
        <>
            <div class="row mg-t-10 mb-2">
                {
                    filterStates.map((item) => {
                        return <div class="col-lg-3">
                            <label class="rdiobox" onClick={()=>click(item.value)}>{filterStatus == item.value ? <input name="rdio" checked="checked" type="radio" /> : <input name="rdio" type="radio" />} <span>{item.label}</span></label>
                        </div>

                    })
                }


            </div>   </>
    )
}

export default TicketFilter