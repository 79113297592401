import React, { useEffect, useState } from 'react'
import TextBox from '../../controls/TextBox/TextBox';
import TextArr from '../../controls/TextArr/TextArr';
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import API from '../../modules/api'

import Utils from '../../modules/utils';

import { useNavigate } from "react-router-dom";

function AddAnswerTicket({ticket,callTicket}) {
     const util = new Utils();

    const [itemUseState, setItemUseState] = useState({parent_id:ticket.id,group_id:ticket.group_id,title:`Re:${ticket.title}`,message:null})



    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;


        // alert(name)
        // alert(value)
        setItemUseState({
            ...itemUseState,
            [name]: value,
        });

    };
    const handleClick = (e) => {
        if(itemUseState.group_id==null || isNaN(itemUseState.group_id) || itemUseState.title==null || itemUseState.message==null){
            util.showAlert("اخطار!","اطلاعات ناقص است",'error');
        }else{
            (new API()).sendPostWithToken("/api/tickets",itemUseState, (result) => {
                setItemUseState({...itemUseState,message:""})
                util.showAlert("تائید!","اطلاعات ارسال شد");
                callTicket();
            })
    
        }
    };

    useEffect(() => {

        // console.log(itemUseState)
        // props.changed(itemUseState)
    }, [itemUseState])
    return (
        <PageCard>
        <div className="row row-sm">
            <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="">

                    <TextArr  title="پیام" rows={10} name="message" value={itemUseState.message} changed={handleChange} />








                </div>
            </div>
        </div>
        <Row>
            <Col size="lg-9">

            </Col>
            <Col size="lg-3">
                <button className='btn btn-secondary  px-5' onClick={handleClick}>ارسال</button>
            </Col>
        </Row>
    </PageCard>
    )
}

export default AddAnswerTicket