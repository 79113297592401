import React, { useEffect, useState, useRef } from 'react'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import './HomeUniqFeatures.css'

import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../SettingControls/TextEditor'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import TextEditLink from '../../SettingControls/TextEditLink'
import BoolSelector from '../../SettingControls/BoolSelector'

function HomeUniqFeatures() {


    const [showElement, setShowElement] = useState(true);
    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null)
    const [homeFeatureState, setHomeFeatureState] = useState(
        {
            icon1_url: "./assets/front-images/features/features_icon_01.svg",
            icon2_url: "./assets/front-images/features/features_icon_02.svg",
            icon3_url: "./assets/front-images/features/features_icon_03.svg",

            title: "Unique features",
            title_edit: false,
            pl1: "Lorem Ipsum is simply dummy text of the printing and typese tting indus",
            pl1_edit: false,
            pl2: "orem Ipsum has beenthe standard dummy text ever since",
            pl2_edit: false,
            title1: "Highly customizable",
            title1_edit: false,
            dsc1: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            dsc1_edit: false,
            linktext1: "DISCOVER MORE ",
            linktext1_edit: false,
            link1: "/",
            link1_edit: false,
            title2: "Highly customizable",
            title2_edit: false,
            dsc2: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            dsc2_edit: false,
            linktext2: "DISCOVER MORE ",
            linktext2_edit: false,
            link2: "/",
            link2_edit: false,
            title3: "Highly customizable",
            title3_edit: false,
            dsc3: "Lorem Ipsum is simply dummy text ofthe printing and type setting indus ideas orem Ipsum has beenthe",
            dsc3_edit: false,
            linktext3: "DISCOVER MORE ",
            linktext3_edit: false,
            link3: "/",
            link3_edit: false,

        }
    );


    useEffect(() => {
        loadData();
      


    }, [])


    const loadData = () => {

        let arrNames = ['home-feature-unique-show'];
        let arrImges = [];
        let arrTexts = [];
        let arrStatic = ['home-feature-unique-title', 'home-feature-unique-pl1', 'home-feature-unique-pl2'];
        arrNames = [...arrNames, ...arrStatic];
        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-feature-unique-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-feature-unique-icon${index}-attach-id`];
            const arr = ['title', 'dsc', 'linktext', 'link'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-feature-unique-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-feature-unique-${arr[index2]}${index}`]

            }

        }
        let obj = {};
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)
                if (result['home-feature-unique-show'] != null) {
                    setShowElement((result['home-feature-unique-show'] == 'true'))
                }
                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-feature-unique-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`icon${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-feature-unique-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }
                for (let index = 0; index < arrStatic.length; index++) {
                    const element = arrStatic[index];
                    const elementName = element.replace('home-feature-unique-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }
                console.log("WER", obj)
                setHomeFeatureState({ ...homeFeatureState, ...obj });
            });

        } catch (error) {

        }



    }

    const imageDoubleClick = (event) => {
        event.preventDefault();
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedImage(event.target);
    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedImageState != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-feature-unique-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
                setHomeFeatureState({
                    ...homeFeatureState,
                    [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
                })
            })

            setShowModal(false)
        }

    }
    const selectedText = (event, name) => {
        event.preventDefault();

        setHomeFeatureState({ ...homeFeatureState, [`${name}_edit`]: true })
        console.log(homeFeatureState);
    }
    const closeText = (event, name) => {
        event.preventDefault();

        setHomeFeatureState({ ...homeFeatureState, [`${name}_edit`]: false })
        console.log(homeFeatureState);
    }
    const saveText = (event, cntrl) => {
        event.preventDefault();
        console.log(cntrl)
        if (cntrl != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-feature-unique-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
                setHomeFeatureState({ ...homeFeatureState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
            })

            setShowModal(false)
        }

    }
    const onShowElementClicked = (val) => {

        setShowElement(val);

    }
    useEffect(() => {

        (new API()).sendPostWithToken("/api/settings", { name: `home-feature-unique-show`, val: `${showElement}` }, (result) => {

        })
    }, [showElement])
    return (
       
            <Row>

                <Col size="lg-12">

                    <PageCard>
                    <BoolSelector title="نمایش داده شود؟" click={onShowElementClicked} myState={showElement}/>

                        <section className="row_am unique_section px-2">
                            <div className="container">
                                <div className="section_title aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2>
                                        {
                                            !homeFeatureState.title_edit ? <span  ><TextEditLink name="title" selectedText={selectedText} />{homeFeatureState.title}</span> :
                                                <span>
                                                    <TextEditor name="title" myState={homeFeatureState} close={closeText} save={saveText} />

                                                </span>
                                        }
                                    </h2>
                                    <p>
                                        {
                                            !homeFeatureState.pl1_edit ? <span  ><TextEditLink name="pl1" selectedText={selectedText} />{homeFeatureState.pl1}</span> :
                                                <span>
                                                    <TextEditor name="pl1" myState={homeFeatureState} close={closeText} save={saveText} />

                                                </span>
                                        }

                                        <br />
                                        {
                                            !homeFeatureState.pl2_edit ? <span  ><TextEditLink name="pl2" selectedText={selectedText} />{homeFeatureState.pl2}</span> :
                                                <span>
                                                    <TextEditor name="pl2" myState={homeFeatureState} close={closeText} save={saveText} />

                                                </span>
                                        }
                                    </p>
                                </div>
                                <div className="features_inner">
                                    <div className="feature_card">
                                        <div className="icons">
                                            <img id="icon1" src={homeFeatureState.icon1_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                            <div className="dot_block">
                                                <span className="dot_anim">
                                                </span><span className="dot_anim">
                                                </span><span className="dot_anim">
                                                </span>
                                            </div>
                                        </div>
                                        <div className="inner_text">
                                            <h3>
                                                {
                                                    !homeFeatureState.title1_edit ? <span  ><TextEditLink name="title1" selectedText={selectedText} />{homeFeatureState.title1}</span> :
                                                        <span>
                                                            <TextEditor name="title1" myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </h3>

                                            <p>
                                                {
                                                    !homeFeatureState.dsc1_edit ? <span  ><TextEditLink name="dsc1" selectedText={selectedText} />{homeFeatureState.dsc1}</span> :
                                                        <span>
                                                            <TextEditor name="dsc1" type='textarea' myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </p>
                                            {
                                                !homeFeatureState.link1_edit ? <span  ><TextEditLink name="link1" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="link1" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }
                                            <a href={homeFeatureState.link1} className="btn text_btn">

                                                {homeFeatureState.linktext1} <i className="icofont-arrow-right"></i>
                                            </a>

                                            {
                                                !homeFeatureState.linktext1_edit ? <span  ><TextEditLink name="linktext1" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="linktext1" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }
                                        </div>
                                    </div>
                                    <div className="feature_card"><div className="icons">
                                        <img id="icon2" src={homeFeatureState.icon2_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                        <div className="dot_block">
                                            <span className="dot_anim">
                                            </span><span className="dot_anim">
                                            </span><span className="dot_anim">
                                            </span>
                                        </div>
                                    </div>
                                        <div className="inner_text">
                                            <h3>
                                                {
                                                    !homeFeatureState.title2_edit ? <span  ><TextEditLink name="title2" selectedText={selectedText} />{homeFeatureState.title2}</span> :
                                                        <span>
                                                            <TextEditor name="title2" myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </h3>
                                            <p>
                                                {
                                                    !homeFeatureState.dsc2_edit ? <span  ><TextEditLink name="dsc2" selectedText={selectedText} />{homeFeatureState.dsc2}</span> :
                                                        <span>
                                                            <TextEditor name="dsc2" type='textarea' myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </p>



                                            {
                                                !homeFeatureState.link2_edit ? <span  ><TextEditLink name="link2" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="link2" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }
                                            <a href={homeFeatureState.link2} className="btn text_btn">

                                                {homeFeatureState.linktext2} <i className="icofont-arrow-right"></i>
                                            </a>

                                            {
                                                !homeFeatureState.linktext2_edit ? <span  ><TextEditLink name="linktext2" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="linktext2" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }





                                        </div>
                                    </div>
                                    <div className="feature_card"><div className="icons">
                                        <img id="icon3" src={homeFeatureState.icon3_url} alt="image" onDoubleClick={(event) => imageDoubleClick(event)} />
                                        <div className="dot_block">
                                            <span className="dot_anim">
                                            </span><span className="dot_anim">
                                            </span><span className="dot_anim">
                                            </span>
                                        </div>
                                    </div>
                                        <div className="inner_text">
                                            <h3>
                                                {
                                                    !homeFeatureState.title3_edit ? <span  ><TextEditLink name="title3" selectedText={selectedText} />{homeFeatureState.title3}</span> :
                                                        <span>
                                                            <TextEditor name="title3" myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </h3>

                                            <p>
                                                {
                                                    !homeFeatureState.dsc3_edit ? <span  ><TextEditLink name="dsc3" selectedText={selectedText} />{homeFeatureState.dsc3}</span> :
                                                        <span>
                                                            <TextEditor name="dsc3" type='textarea' myState={homeFeatureState} close={closeText} save={saveText} />

                                                        </span>
                                                }
                                            </p>


                                            {
                                                !homeFeatureState.link3_edit ? <span  ><TextEditLink name="link3" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="link3" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }
                                            <a href={homeFeatureState.link3} className="btn text_btn">

                                                {homeFeatureState.linktext3} <i className="icofont-arrow-right"></i>
                                            </a>

                                            {
                                                !homeFeatureState.linktext3_edit ? <span  ><TextEditLink name="linktext3" selectedText={selectedText} /></span> :
                                                    <span>
                                                        <TextEditor name="linktext3" myState={homeFeatureState} close={closeText} save={saveText} />

                                                    </span>
                                            }






                                        </div>
                                    </div>
                                </div>


                            </div>
                        </section>


                    </PageCard>
                </Col>
                {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}
            </Row>

      
    )
}

export default HomeUniqFeatures