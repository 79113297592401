import jalaali from 'jalaali-js';
import Swal from 'sweetalert2';

class Utils {
    formattedDate(currentDate) {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }
    toPersianDate(date){
        const jalaaliDate = jalaali.toJalaali(date);

        // Custom format: 1399/01/01 (Year/Month/Day)
        const formattedPersianDate = `${jalaaliDate.jy}/${String(jalaaliDate.jm).padStart(2, '0')}/${String(jalaaliDate.jd).padStart(2, '0')}`;
      return formattedPersianDate;
    }
    toPersianDateTime(date){
        const jalaaliDate = jalaali.toJalaali(date);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        // Custom format: 1399/01/01 (Year/Month/Day)
        const formattedPersianDate = `${jalaaliDate.jy}/${String(jalaaliDate.jm).padStart(2, '0')}/${String(jalaaliDate.jd).padStart(2, '0')} ${hours}:${minutes}:${seconds}`;
      return formattedPersianDate;
    }
    getDateCurrentDifference(date2){
        let date1 = new Date();
       return this. getDateDifference(date1, date2)
    }
    getDateDifference(date1, date2) {
        const diffInMilliseconds = date2 - date1;
      
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const seconds = diffInSeconds % 60;
      
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const minutes = diffInMinutes % 60;
      
        const diffInHours = Math.floor(diffInMinutes / 60);
        const hours = diffInHours % 24;
      
        const diffInDays = Math.floor(diffInHours / 24);
      
        return {
          days: diffInDays,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      }
    addTimeToCurrentDate(amount, unit) {
        let date = new Date();
        console.log("BBB", date)
        return this.addTimeToDate(date, amount, unit)
    }
    addTimeToDate(date, amount, unit) {
        switch (unit) {
            case 'years':
                date.setFullYear(date.getFullYear() + amount);
                break;
            case 'months':
                date.setMonth(date.getMonth() + amount);
                break;
            case 'days':
                date.setDate(date.getDate() + amount);
                break;
            case 'hours':
                date.setHours(date.getHours() + amount);
                break;
            case 'minutes':
                date.setMinutes(date.getMinutes() + amount);
                break;
            case 'seconds':
                date.setSeconds(date.getSeconds() + amount);
                break;
            default:
                throw new Error('Invalid unit');
        }
        return date;
    }

    translateWord(langTarget,word,funcSuccess){
        const myHeaders = new Headers();
        myHeaders.append("one-api-token", "427980:66e41aaf66540");
        myHeaders.append("Content-Type", "application/json");
        
        const raw = JSON.stringify({
          "source": "fa",
          "target": langTarget,
          "text": word
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };
        
        fetch("https://api.one-api.ir/translate/v1/google/", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            funcSuccess((JSON.parse(result)).result)
            // setItemUseState({
            //     ...itemUseState,
            //     translate: (JSON.parse(result)).result,
            // });
          
// console.log("TTTT",itemUseState)
        } )
        .catch((error) => console.error(error));

    }

    get_file_type(ext){
        const image_types=[".jpg",".jpeg",".bmp",".gif",".png",".svg"]
        if(image_types.includes(ext))
            return "image";
        const video_types=[".mp4",".mkv",".wave"]
        if(video_types.includes(ext))
            return "video";
        const word_types=[".doc",".docx"]
        if(word_types.includes(ext))
            return "word";


        const sound_types=[".mp3"]
        if(word_types.includes(ext))
            return "word";
    }

    showDeleteMessage(funcOk){
        Swal.fire({
            title: "مطمئن هستید",
            text: "آیا اطلاعات حذف شود؟!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "بلی حذف کنید!",
            cancelButtonText: "خیر"
          }).then((result) => {
            if (result.isConfirmed) { 
                funcOk();

             }
        });
    }
    showAlert(title,message,type="success"){
        Swal.fire({
            title: title,
            text: message,
            icon: type
          });
    }
}
export default Utils;
//module.exports = Utils;