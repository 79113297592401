import React, { useEffect, useState, useRef } from 'react'
import PageTitle from '../../../components/template/PageTitle/PageTitle'
import PageWrapper from '../../../components/template/PageWrapper/PageWrapper'
import HomeBanner from '../../../components/Home/HomeBanner/HomeBanner'
import HomeUniqFeatures from '../../../components/Home/HomeUniqFeatures/HomeUniqFeatures'
import HomeAnalyze from '../../../components/Home/HomeAnalyze/HomeAnalyze'
import HomeCollaborate from '../../../components/Home/Collaborate/Collaborate'
import HomeSetting from '../../../components/Home/HomeSetting/HomeSetting'
import HomeProducts from '../../../components/Home/HomeProducts/HomeProducts'

function HomeSettings() {
    const [tabsState, setTabsState] = useState([
      //  {title:"عمومی",component:<HomeSetting/>},
        {title:"بنر",component:<HomeBanner/>},
        {title:"فیچرهای یونیک",component:<HomeUniqFeatures/>},
        {title:"آنالیز",component:<HomeAnalyze/>},
        {title:"همکاری",component:<HomeCollaborate/>},
        {title:"محصولات",component:<HomeProducts/>},
        // {title:"",component:<HomeBanner/>},
        // {title:"",component:<HomeBanner/>},
        // {title:"",component:<HomeBanner/>},
    ])
    const [homeState, setHomeState] = useState([])
    const [activeTabState, setActiveTabState] = useState({index:0,component:tabsState[0].component})
   const onTabClicked=(event,index)=>{
    event.preventDefault();
   
    
    setActiveTabState({...activeTabState,...{index:index,component:tabsState[index].component}})
   }
    return (
        <PageWrapper>
            <PageTitle title="تنظیمات صفحه اصلی" />
            <div className="example">
                <div className="border">
                    <div className="bg-gray-300 nav-bg">
                        <nav className="nav nav-tabs">
                            {
                                   [...Array(tabsState.length)].map((x, i) => {


                                    return   <a className={`nav-link ${i==activeTabState.index?"active":""}`} key={i} data-toggle="tab" href="#tabCont" onClick={(event)=>onTabClicked(event,i)}>{tabsState[i].title} </a>

                                   })
                            }
                           
                        </nav>
                    </div>
                    <div className="card-body tab-content">
                        <div className="tab-pane active  show">
                            {activeTabState.component}
                        </div>
                     
                    </div>
                </div>
            </div>
        </PageWrapper>

    )
}

export default HomeSettings