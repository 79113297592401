import React, { useEffect, useState, useRef } from 'react'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
import API from '../../../modules/api'
import Utils from '../../../modules/utils'
import $ from "jquery";
import './AttachSelector.css'
import AttachSelectorItem from './AttachSelectorItem'
function AttachSelector(props) {
    const utl = new Utils();
    const [attachsState, setAttachsState] = useState([])
    useEffect(() => {

        (new API()).sendGETWithToken("/api/attachments", (result) => {

            console.log("BHZ", props.filter.length)

            if (props.filter.length > 0) {
                var temp = result.filter((item) => {

                    return props.filter.includes(utl.get_file_type(item.ext))
                })
                setAttachsState(temp);
            }
            else {
                setAttachsState(result);
            }
           
     

        })

    }, [props.filter, props.isShow])


    const AddFileClicked = (event) => {

        $("#file").click();
       
        
    }

    const AddFileInputClicked = (event) => {
        console.log("FFF", event.target.files);


       
        (new API()).sendPostFileWithToken("/api/attachments", event.target.files[0], function (result) {
            console.log(result);
            setAttachsState(JSON.parse(result));
        })
    }

    return (
        <Wrapper>


            <div className={"modal show" + (props.isShow ? "show" : "")} id="modaldemo1" aria-modal="true" style={{ paddingRight: "17px", display: (props.isShow ? "block" : "none") }}>
                <div className="modal-dialog  modal-fullscreen modal-dialog-scrollable" role="document">
                    <div className="modal-content modal-content-demo">
                        <div className="modal-header">
                            <h6 className="modal-title">فایلهای سیستم</h6><button aria-label="بستن" onClick={props.close} className="close" data-dismiss="modal" type="button"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">


                            <div className="border">
                                <div className="bg-gray-300 nav-bg">
                                    <nav className="nav nav-tabs">
                                        <a className="nav-link active" data-toggle="tab" href="#tabCont1">فایلها</a>
                                        <a className="nav-link" data-toggle="tab" href="#tabCont2">آپلود فایل </a>
                                    </nav>
                                </div>
                                <div className="card-body tab-content">
                                    <div className="tab-pane show active" id="tabCont1">


                                        <div className='row row-sm py-3' style={{ background: "#f0f0ff" }}>
                                            {

                                                attachsState.map((item) => {

                                                    return <AttachSelectorItem item={item} select={props.select} key={item.key} />



                                                })

                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="tabCont2">
                                        <div className="btn btn-list mb-2 ">

                                            <button className="btn ripple btn-primary" onClick={AddFileClicked}>
                                                <i className="fe fe-plus-circle mx-1 mt-3"></i>
                                                فایل جدید </button>
                                            <input  type='file' name='file' id='file' className='d-none' onChange={(event) => AddFileInputClicked(event)} />
                                        </div>
                                    </div>

                                </div>
                            </div>






                        </div>
                        <div className="modal-footer">
                            {props.footer}
                            <button className="btn ripple btn-secondary" data-dismiss="modal" type="button" onClick={props.close}>بستن</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal-backdrop " + (props.isShow ? "show" : "")} style={{ display: (props.isShow ? "" : "none") }}></div>
        </Wrapper>
    )
}

export default AttachSelector