import React, { useEffect, useState, useRef, useCallback } from 'react'
import PageTitle from '../../../../components/template/PageTitle/PageTitle'
import PageWrapper from '../../../../components/template/PageWrapper/PageWrapper'
import PageCard from '../../../../controls/PageCard/PageCard'
import Row from '../../../../controls/Row/Row'
import Col from '../../../../controls/Col/Col'
import * as appConsts from '../../../../store/consts'
import API from '../../../../modules/api'
import './SiteFooter.css'
import AttachSelector from '../../../../components/Attachments/AttachSelector/AttachSelector'
import TextEditor from '../../../../components/SettingControls/TextEditor'
import TextEditLink from '../../../../components/SettingControls/TextEditLink'
import BoolSelector from '../../../../components/SettingControls/BoolSelector'
import Wrapper from '../../../../hoc/Wrapper/Wrapper'
import ModalSocialNetworks from '../../../../components/SiteFooter/ModalSocialNetworks'
function SiteFooter() {

    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null);
    const [netWorksState, setNetWorksState] = useState(
        [
            { name: "telegram", icon: "fab fa-telegram-plane", link: "/", isVisible: true, isEdit: false },
            { name: "whatsapp", icon: "fab fa-whatsapp", link: "", isVisible: true, isEdit: false },
            { name: "twitter", icon: "fab fa-twitter", link: "", isVisible: true, isEdit: false },
            { name: "facebook", icon: "fab fa-facebook-f", link: "", isVisible: true, isEdit: false },
            { name: "youtube", icon: "fab fa-youtube", link: "", isVisible: true , isEdit: false},
            { name: "instagram", icon: "fab fa-instagram", link: "", isVisible: true , isEdit: false},
        ]
    );
    const [homeState, setHomeState] = useState(
        {
            logo_url: "./assets/front-images/Logo.png",

            icon1_url: "./assets/front-images/contacts/contact_01.png",
            icon2_url: "./assets/front-images/contacts/contact_02.png",
            icon3_url: "./assets/front-images/contacts/contact_03.png",


            contact_title: "Contact us",
            contact_title_edit: false,

            dsc: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",
            dsc_edit: false,

            link_title1: "Useful Links",
            link_title1_edit: false,
            link_name1: "footer1",
            link_name1_edit: false,
            link_items1: [],

            link_title2: "Reach us",
            link_title2_edit: false,
            link_name2: "footer2",
            link_name2_edit: false,
            link_items2: [],


            title1: "Reach us",
            title1_edit: false,
            dsc1: "599, Vilium Crossing, NJ",
            dsc1_edit: false,
            title2: "Call us",
            title2_edit: false,
            dsc2: "+1-900-123 4567",
            dsc2_edit: false,

            title3: "Email us",
            title3_edit: false,
            dsc3: "support@example.com",
            dsc3_edit: false,





        }
    );



    const imageDoubleClick = (event) => {
        event.preventDefault();
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedImage(event.target);
    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedImageState != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-footer-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
                setHomeState({
                    ...homeState,
                    [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
                })
            })

            setShowModal(false)
        }

    }
    const selectedText = (event, name) => {
        event.preventDefault();

        setHomeState({ ...homeState, [`${name}_edit`]: true })
        console.log(homeState);
    }
    const closeText = (event, name) => {
        event.preventDefault();

        setHomeState({ ...homeState, [`${name}_edit`]: false })
        console.log(homeState);
    }
    const saveText = (event, cntrl) => {
        event.preventDefault();
        console.log(cntrl)
        if (cntrl != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-footer-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
                setHomeState({ ...homeState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
            })

            setShowModal(false)
        }

    }
    const onNetworkClicked=(event,item)=>{
        event.preventDefault();
     const nt =   netWorksState.map((tt)=>{
        if(tt.name==item.name){
            tt.isEdit=true;
        }
        return tt;
     });
     setNetWorksState([...nt]);
    }
    const onCloseEditNetworkClicked=(event,item)=>{
        event.preventDefault();
     const nt =   netWorksState.map((tt)=>{
        if(tt.name==item.name){
            tt.isEdit=false;
           
        }
        return tt;
     });
     setNetWorksState([...nt]);
    }
    const onSaveEditNetworkClicked=(event,item)=>{
        event.preventDefault();
     let nt =   netWorksState.map((tt)=>{
        if(tt.name==item.name){
            tt.isVisible=item.isVisible;
            tt.link=item.link;
            
            tt.isEdit=false;
        }
        return tt;
     });
     (new API()).sendPostWithToken("/api/settings", { name: `home-footer-social-${item.name}_isVisible`, val: `${item.isVisible}` });
     (new API()).sendPostWithToken("/api/settings", { name: `home-footer-social-${item.name}_link`, val: item.link });
     console.log("ASWA",nt);
    setNetWorksState([...nt]);
    }

    useEffect(() => {
        loadData();



    }, [])

    useEffect(() => {
        loadMenus({ ...homeState });

    }, [homeState.link_name1, homeState.link_name2])
    const loadMenus = (obj) => {
        let arrNames = [obj.link_name1, obj.link_name2];
        (new API()).sendPostWithoutToken(`/api/links/names`, { names: arrNames }, (result) => {
            console.log("MNU", result)
            // let obj = {};
            for (let index = 0; index < arrNames.length; index++) {
                const element = arrNames[index];
                if (result[arrNames[index]] != null) {
                    obj[`link_items${index + 1}`] = result[arrNames[index]];
                } else {
                    obj[`link_items${index + 1}`] = [];
                }
            }

            setHomeState({ ...homeState, ...obj });

        });
    }



    const loadData = () => {

        let arrNames = [];
        let arrImges = ["home-footer-logo-attach-id"];
        let arrTexts = [];
        let arrStatics = ['home-footer-dsc', 'home-footer-contact_title'];
        arrNames = [...arrNames, ...arrImges];
        arrNames = [...arrNames, ...arrStatics];
        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-footer-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-footer-icon${index}-attach-id`];
            const arr = ['title', 'dsc'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-footer-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-footer-${arr[index2]}${index}`]

            }



        }
        for (let index = 1; index <= 2; index++) {
            arrNames = [...arrNames, `home-footer-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-footer-icon${index}-attach-id`];
            const arr = ['link_title', 'link_name'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-footer-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-footer-${arr[index2]}${index}`]

            }



        }
        for (let index = 0; index < netWorksState.length; index++) {
            const element = netWorksState[index];
            arrNames=[...arrNames,`home-footer-social-${element.name}_isVisible`];
            arrNames=[...arrNames,`home-footer-social-${element.name}_link`];
            
        }
        let obj = { ...homeState };
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                console.log("TRE", result)

                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-footer-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-footer-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }
                for (let index = 0; index < arrStatics.length; index++) {

                    const element = arrStatics[index];

                    const elementName = element.replace('home-footer-', '');
                    if (result[element] != null)
                        obj[elementName] = result[element];

                }
            
                const nt=netWorksState.map((item)=>{
                    if (result[`home-footer-social-${item.name}_isVisible`] != null)
                        item.isVisible=(result[`home-footer-social-${item.name}_isVisible`]=="true")
                    if (result[`home-footer-social-${item.name}_link`] != null)
                        item.link=result[`home-footer-social-${item.name}_link`];

                    return item;
                });
               
                console.log("NTB",nt);
                setNetWorksState([...nt]);
              
                loadMenus(obj);
                //   setHomeState({ ...homeState, ...obj });
            });

        } catch (error) {

            console.log("ERR", error)
        }



    }


    return (
        <PageWrapper>
            <PageTitle title="تنظیمات فوتر" />
            <Row>

                <Col size="lg-12">

                    <PageCard>
                        <div className='footer'>
                            <div className="top_footer" id="contact">

                                <div className="container">

                                    <div className="row">


                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="abt_side">
                                                <div className="logo">

                                                    <img id="logo" onDoubleClick={(event) => imageDoubleClick(event)} src={homeState.logo_url} alt="image" /></div>
                                                <p>

                                                    {
                                                        !homeState.dsc_edit ? <span  ><TextEditLink name="dsc" selectedText={selectedText} />{homeState.dsc}</span> :
                                                            <span>
                                                                <TextEditor type='textarea' name="dsc" myState={homeState} close={closeText} save={saveText} />

                                                            </span>
                                                    }

                                                </p>
                                                <div className="news_letter_block">
                                                    <form action="submit">
                                                        <div className="form-group">
                                                            <input type="email" placeholder="Enter your email" className="form-control" />
                                                            <button className="btn"><i className="fe fe-paper-plane"></i></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            [...Array(2)].map((x, i) => {
                                                return <div className="col-lg-2 col-md-6 col-12">
                                                    <div className="links">
                                                        <h3>

                                                            {
                                                                !homeState[`link_title${i + 1}_edit`] ? <span  ><TextEditLink name={`link_title${i + 1}`} selectedText={selectedText} /> {homeState[`link_title${i + 1}`]}</span> :
                                                                    <span>
                                                                        <TextEditor name={`link_title${i + 1}`} myState={homeState} close={closeText} save={saveText} />

                                                                    </span>
                                                            }
                                                            {
                                                                !homeState[`link_name${i + 1}_edit`] ? <span  ><TextEditLink name={`link_name${i + 1}`} selectedText={selectedText} /></span> :
                                                                    <span>
                                                                        <TextEditor name={`link_name${i + 1}`} myState={homeState} close={closeText} save={saveText} />

                                                                    </span>
                                                            }

                                                        </h3>
                                                        <ul>
                                                            {
                                                                [...Array(homeState[`link_items${i + 1}`].length)].map((x2, i2) => {


                                                                    return <li><a href={homeState[`link_items${i + 1}`][i2].href}>{homeState[`link_items${i + 1}`][i2].title}</a></li>
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                </div>
                                            })
                                        }




                                        <div className="col-lg-3 col-md-6 col-12">
                                            <div className="try_out">
                                                <h3>
                                                    {
                                                        !homeState.contact_title_edit ? <span  ><TextEditLink name="contact_title" selectedText={selectedText} />{homeState.contact_title}</span> :
                                                            <span>
                                                                <TextEditor name="contact_title" myState={homeState} close={closeText} save={saveText} />

                                                            </span>
                                                    }
                                                </h3>
                                                <ul>


                                                    {
                                                        [...Array(3)].map((x, i) => {
                                                            return <li>
                                                                <span className="icon">
                                                                    <img id={`icon${i + 1}`} onDoubleClick={(event) => imageDoubleClick(event)} src={homeState[`icon${i + 1}_url`]} alt="image" />
                                                                </span>
                                                                <div className="text">
                                                                    <p>
                                                                        {
                                                                            !homeState[`title${i + 1}_edit`] ? <Wrapper  ><TextEditLink name={`title${i + 1}`} selectedText={selectedText} /> {homeState[`title${i + 1}`]}</Wrapper> :
                                                                                <Wrapper>
                                                                                    <TextEditor name={`title${i + 1}`} myState={homeState} close={closeText} save={saveText} />

                                                                                </Wrapper>
                                                                        }



                                                                        <br />


                                                                        {
                                                                            !homeState[`dsc${i + 1}_edit`] ? <Wrapper  ><TextEditLink name={`dsc${i + 1}`} selectedText={selectedText} /> {homeState[`dsc${i + 1}`]}</Wrapper> :
                                                                                <Wrapper>
                                                                                    <TextEditor name={`dsc${i + 1}`} myState={homeState} close={closeText} save={saveText} />

                                                                                </Wrapper>
                                                                        }

                                                                    </p>
                                                                </div>
                                                            </li>

                                                        })

                                                    }


                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="bottom_footer">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <p>© Copyrights 2023. All rights reserved.</p></div>
                                        <div className="col-md-4"><ul className="social_media">
                                            {
                                                netWorksState.map((item) => {
                                                    return (!item.isEdit?<li><a className={!item.isVisible?"bg-danger":""} href={item.link} onClick={(event)=>onNetworkClicked(event,item)}><i className={item.icon}></i></a></li>
                                                        :<li>
                                                           <ModalSocialNetworks item={item} close={onCloseEditNetworkClicked} save={onSaveEditNetworkClicked}/>

                                                        </li>
                                                    )
                                                })

                                            }

                                        </ul>
                                        </div>
                                        <div className="col-md-4">
                                            <p className="developer_text">Design &amp; developed by <a href="https://themeforest.net/user/kalanidhithemes" target="blank">Kalanidhi Themes</a></p></div></div></div></div>
                        </div>

                    </PageCard>
                </Col>
            </Row>
            {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}

        </PageWrapper>

    )
}

export default SiteFooter