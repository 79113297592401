import React, { useEffect, useState, useRef } from 'react'

function BoolSelector({title,myState,click}) {

  return (
    <div className='m-3 d-flex'>
    <div className='mx-2'>{title}</div>
    <div onClick={() => click(true)} className={`mx-1 main-toggle main-toggle-success ${myState ? 'on' : ''}`}>
      <span></span>
    </div>

    <div onClick={() => click(false)} className={`mx-1 main-toggle main-toggle-secondary ${!myState ? 'on' : ''}`}>
      <span></span>
    </div>
  </div>
  )
}

export default BoolSelector