import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import PageWrapper from '../../components/template/PageWrapper/PageWrapper'
import PageTitle from '../../components/template/PageTitle/PageTitle'
import PageCard from '../../controls/PageCard/PageCard'
import Row from '../../controls/Row/Row'
import Col from '../../controls/Col/Col'
import { useParams } from 'react-router';
import Utils from '../../modules/utils';
import TicketViewCard from '../../components/Tickets/TicketViewCard'
import TextBox from '../../controls/TextBox/TextBox';
import TextArr from '../../controls/TextArr/TextArr';
import Select2 from '../../controls/Select2/Select2';
import AddAnswerTicket from '../../components/Tickets/AddAnswerTicket'
function TicketView() {
    const { id } = useParams();
    const [ticketState, setTicketState] = useState(null);
    const util = new Utils();

    useEffect(() => {
        (new API()).sendGETWithToken(`/api/tickets/${id}`, (result) => {

            setTicketState(result);
        })

    }, [id])

    const callTicket=()=>{
        (new API()).sendGETWithToken(`/api/tickets/${id}`, (result) => {

            setTicketState(result);
        })
    }
    return (
        <PageWrapper>
            <PageTitle title="مشاهده تیکت" />
            <Row>
                <Col size="lg-12">
                    {ticketState != null ? <>
                        <TicketViewCard item={ticketState} />

                        {ticketState.tickets != null ? <>
                            {
                                ticketState.tickets.map((item) => {
                                    return <TicketViewCard item={item} />

                                })

                            }
                        </> : null}

                        <AddAnswerTicket ticket={ticketState} callTicket={callTicket}/>
                    </> : null}






                
                </Col>
            </Row>
        </PageWrapper>
    )
}

export default TicketView