import React, { useEffect, useState, useRef } from 'react'
import PageCard from '../../../controls/PageCard/PageCard'
import Row from '../../../controls/Row/Row'
import Col from '../../../controls/Col/Col'
import * as appConsts from '../../../store/consts'
import API from '../../../modules/api'
import AttachSelector from '../../../components/Attachments/AttachSelector/AttachSelector'
import $ from 'jquery'
import BoolSelector from '../../SettingControls/BoolSelector'
import TextEditor from '../../SettingControls/TextEditor'
import TextEditLink from '../../SettingControls/TextEditLink'
import './HomeProducts.css'
import Wrapper from '../../../hoc/Wrapper/Wrapper'
function HomeProducts() {
    const [showElement, setShowElement] = useState(true);
    const [showPrice, setShowPrice] = useState(true);
    const cmbProducts = useRef(null)
    const [filterModal, setFilterModal] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageState, setSelectedImage] = useState(null)
    const [productsState, setProductsState] = useState([]);
    const [productAddState, setProductAddState] = useState(null);
    const [homeState, setHomeState] = useState(
        {
            products: [],
            productsInfo: [],
            image1_url: "./assets/front-images/Free-Trial-01.svg",
            image2_url: "./assets/front-images/unlimited.png",
            image3_url: "./assets/front-images/Premium.svg",


            title: "Best plans, pay what you use",
            title_edit: false,
            dsc: "Lorem Ipsum is simply dummy text of the printing and typese tting indus lorem Ipsum has beenthe standard dummy text ever since.",
            dsc_edit: false,

            btn_title: "BUY NOW",
            btn_title_edit: false,



        }
    );


    const onShowElementClicked = (val) => {

        setShowElement(val);

    }
    useEffect(() => {

        (new API()).sendPostWithToken("/api/settings", { name: `home-products-show`, val: `${showElement}` }, (result) => {


        })
    }, [showElement])


    const onShowPriceClicked = (val) => {

        setShowPrice(val);

    }
    useEffect(() => {

        (new API()).sendPostWithToken("/api/settings", { name: `home-products-price-show`, val: `${showPrice}` }, (result) => {


        })
    }, [showPrice])
    useEffect(() => {
        loadProducts();
        //loadData();
    }, [productAddState])

    useEffect(() => {
        loadProducts();
       // loadData();
    }, [])

     const  loadProducts = ()  => {

        try {
            (new API()).sendGETWithToken("/api/products-packages", (result) => {
                setProductsState(result);
                $(cmbProducts.current).select2();

                loadData(function(reProducts,obj){
                        $(cmbProducts.current).val(reProducts.split(','))
                        $(cmbProducts.current).trigger('change');

                        let packages = [];
                        for (let index = 0; index < reProducts.split(',').length; index++) {
                            const element = reProducts.split(',')[index];
                            const temp = result.filter((item) => {
                                const pkgs = item.packages.filter((itemb) => {

                                    if (element == itemb.id) {

                                        packages = [...packages, { productName: item.name, ...itemb }]
                                        return true
                                    }
                                    return false;

                                })


                                return pkgs.length > 0
                            });



                        }

                       
                           setHomeState({ ...homeState,...obj, products: reProducts.split(','), productsInfo: packages });



                })
               
            })



            $(cmbProducts.current).on('select2:select', function (e) {
                (new API()).sendPostWithToken("/api/settings", { name: `home-products`, val: `${$(cmbProducts.current).val()}` }, (result) => {

                    setHomeState({ ...homeState, products: $(cmbProducts.current).val() });
                    setProductAddState(new Date())
                })
            });
            $(cmbProducts.current).on('select2:unselect', function (e) {
                (new API()).sendPostWithToken("/api/settings", { name: `home-products`, val: `${$(cmbProducts.current).val()}` }, (result) => {

                    setHomeState({ ...homeState, products: $(cmbProducts.current).val() });
                    setProductAddState(new Date())
                })

            });

        } catch (error) {

        }


    }



    const loadData = (funcProducts) => {

        let arrNames = ['home-products-show','home-products-price-show'];
        let arrImges = [];
        let arrTexts = [];

        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-product-image${index}-attach-id`];
            arrImges = [...arrImges, `home-product-image${index}-attach-id`];
         
        }
      
        const arr = ['title', 'dsc','btn_title'];
        for (let index2 = 0; index2 < arr.length; index2++) {
        
            console.log("POPOP",`home-product-${arr[index2]}`);
            arrNames = [...arrNames, `home-product-${arr[index2]}`];
            arrTexts = [...arrTexts, `home-product-${arr[index2]}`]
            
        }
        arrNames = [...arrNames, "home-products"];

        let obj = {};
        try {

            (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
             
                console.log("TRE", result);

                if (result['home-products-show'] != null) {
                    setShowElement((result['home-products-show'] == 'true'))
                }
                if (result['home-products-price-show'] != null) {
                    setShowPrice((result['home-products-price-show'] == 'true'))
                }
                for (let index = 0; index < arrImges.length; index++) {
                    const element = arrImges[index];
                    const elementName = element.replace('home-product-', '').replace('-attach-id');
                    if (result[element] != null)
                        obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                }
                for (let index = 0; index < arrTexts.length; index++) {
                    const element = arrTexts[index];
                    const elementName = element.replace('home-product-', '');
                    if (result[element] != null)
                      
                        obj[elementName] = result[element];

                }

             
                if(result["home-products"]!=null){
                
                   
                        funcProducts(result["home-products"],obj)
                }

            });

        } catch (error) {

        }



    }

    const imageDoubleClick = (event) => {
        event.preventDefault();
        setFilterModal(["image"])
        setShowModal(true)
        setSelectedImage(event.target);
    }
    const closeModal = (event) => {
        event.preventDefault();
        setShowModal(false)
    }
    const selectedImage = (event, item) => {
        event.preventDefault();
        if (selectedImageState != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-product-${selectedImageState.id}-attach-id`, val: item.id }, (result) => {
                setHomeState({
                    ...homeState,
                    [`${selectedImageState.id}_url`]: `${appConsts.api_url}/api/attachments/file/${item.id}`
                })
            })

            setShowModal(false)
        }

    }
    const selectedText = (event, name) => {
        event.preventDefault();

        setHomeState({ ...homeState, [`${name}_edit`]: true })
        console.log(homeState);
    }
    const closeText = (event, name) => {
        event.preventDefault();

        setHomeState({ ...homeState, [`${name}_edit`]: false })
        console.log(homeState);
    }
    const saveText = (event, cntrl) => {
        event.preventDefault();
        console.log(cntrl)
        if (cntrl != null) {

            (new API()).sendPostWithToken("/api/settings", { name: `home-product-${cntrl.current.name}`, val: cntrl.current.value }, (result) => {
                setHomeState({ ...homeState, [`${cntrl.current.name}_edit`]: false, [`${cntrl.current.name}`]: cntrl.current.value, })
            })

            setShowModal(false)
        }

    }


    return (
        <Row>

            <Col size="lg-12">

                <PageCard>
                    <BoolSelector title="نمایش داده شود؟" click={onShowElementClicked} myState={showElement} />
                    <BoolSelector title="قیمت نمایش داده شود؟" click={onShowPriceClicked} myState={showPrice} />
                    <Col size="lg-12">
                        <p className="mg-b-10">انتخاب محصولات</p>

                        <select ref={cmbProducts} className="form-control select2" multiple="multiple">

                            {

                                productsState.map((item) => {
                                    return <optgroup label={item.name}>
                                        {
                                            item.packages ? item.packages.map((item2) => {
                                                return <option value={item2.id}>{item2.name}</option>

                                            }) : null

                                        }
                                    </optgroup>
                                })
                            }


                        </select>
                    </Col>

                    <section className="row_am pricing_section aos-init" id="pricing" data-aos="fade-in" data-aos-duration="1000">

                        <div className="container">
                            <div className="section_title aos-init" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <h2>

                                    {
                                        !homeState.title_edit ? <span  ><TextEditLink name="title" selectedText={selectedText} />{homeState.title}</span> :
                                            <span>
                                                <TextEditor name="title" myState={homeState} close={closeText} save={saveText} />

                                            </span>
                                    }

                                </h2>
                                <p>
                                    {
                                        !homeState.dsc_edit ? <Wrapper  ><TextEditLink name="dsc" selectedText={selectedText} />{homeState.dsc}</Wrapper> :
                                            <span>
                                                <TextEditor type='textarea' name="dsc" myState={homeState} close={closeText} save={saveText} />

                                            </span>
                                    }


                                </p>
                            </div>




                            <div className="pricing_pannel monthly_plan active aos-init" data-aos="fade-up" data-aos-duration="1500">

                                <div className="row">
                                    {
                                        [...Array(homeState.productsInfo.length)].map((x, i) => {
                                            return <div className="col-md-4">
                                                <div className="pricing_block">
                                                    <div className="icon">
                                                        <img id={`image${i + 1}`} onDoubleClick={(event) => imageDoubleClick(event)} src={homeState[`image${i + 1}_url`]} alt="image" />
                                                        <div className="dot_block">
                                                            <span className="dot_anim"></span>
                                                            <span className="dot_anim"></span>
                                                            <span className="dot_anim"></span>
                                                        </div>
                                                    </div>
                                                    <div className="pkg_name">
                                                        <h3>{homeState.productsInfo[i].productName}</h3>
                                                        <span>{homeState.productsInfo[i].name}</span>
                                                    </div>
                                                    <span className={`price ${showPrice?'':'d-none'}`}>${homeState.productsInfo[i].dollar_price}<span></span></span>
                                                    <ul className="benifits">

                                                        {
                                                            [...Array(homeState.productsInfo[i].details.length)].map((x2, i2) => {
                                                                return <li className={`${homeState.productsInfo[i].details[i2].val == "[OK]" ? "include" : "exclude"}`}>
                                                                    <p><i className={`fe fe-${homeState.productsInfo[i].details[i2].val == "[OK]" ? "check" : "x"}-circle`}></i>{homeState.productsInfo[i].details[i2].name}</p>
                                                                </li>
                                                            })
                                                        }

                                                    </ul>
                                                    <a href="#" className="btn btn_main">

                                                        {homeState.btn_title}


                                                        <i className="icofont-arrow-right"></i></a>
                                                    {
                                                        !homeState.btn_title_edit ? <span  ><TextEditLink name="btn_title" selectedText={selectedText} /></span> :
                                                            <span>
                                                                <TextEditor name="btn_title" myState={homeState} close={closeText} save={saveText} />

                                                            </span>
                                                    }
                                                </div>
                                            </div>




                                        })

                                    }




                                </div>

                            </div>

                        </div>

                    </section>


                </PageCard>
            </Col>
            {showModal ? <AttachSelector isShow={showModal} close={closeModal} filter={filterModal} select={selectedImage} /> : null}

        </Row>
    )
}

export default HomeProducts