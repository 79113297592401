import React, { useCallback, useEffect, useState } from 'react'
import API from '../../modules/api'
import Swal from 'sweetalert2';
import Utils from '../../modules/utils';
function DetailList(props) {
  const util = new Utils();
  const initialState = { name: "", val: "" };
  const [detailsState, setDetailesState] = useState([])
  const [selectedDetail, setSelectedDetail] = useState(initialState);
  const [selectedAction, setSelectedAction] = useState("");
  useEffect(() => {

    (new API()).sendGETWithToken(`/api/packages/details/${props.item.id}`, (result) => {

      setDetailesState(result);
    })

  }, [props.item])
  const handleChange = (e) => {
    const { name, value } = e.target;

    setSelectedDetail({
      ...selectedDetail,
      [name]: value,
    });

  };

  const onCloseEditClick = (event) => {
    setSelectedAction(null)
    setSelectedDetail({ ...initialState })
  }
  const onEditClick = (event, item) => {
    setSelectedAction("edit")
    setSelectedDetail({ ...item })
  }
  const onDeleteClick = (event, item) => {
    util.showDeleteMessage(()=> {
      (new API()).sendPostWithToken(`/api/packages/details/delete/${item.id}`,{}, (result) => {
        setDetailesState([...result]);
  util.showAlert("تائید!","اطلاعات تغییر یافت");
      })
    });
    
   
 
  }
  const onSaveClick = (event) => {
    event.preventDefault();

    switch (selectedAction) {
      case "edit":
        if (selectedDetail) {
          if (selectedDetail.name.trim().length == 0 || selectedDetail.val.trim().length == 0) {
            Swal.fire("خطا", "اطلاعات ناقص است", "error")
            return;
          }
          (new API()).sendPatchWithToken(`/api/packages/details/${selectedDetail.id}`, selectedDetail, (result) => {
            setSelectedAction(null)
            setSelectedDetail({ ...initialState })
            setDetailesState(result);

          })
        }
        break;
      case null:
      case "":
      case "add":
        if (selectedDetail) {
          if (selectedDetail.name.trim().length == 0 || selectedDetail.val.trim().length == 0) {
            Swal.fire("خطا", "اطلاعات ناقص است", "error")
            return;
          }
          selectedDetail.package_id = props.item.id;
          (new API()).sendPostWithToken(`/api/packages/detail`, selectedDetail, (result) => {
            setSelectedAction(null)
            setSelectedDetail({ ...initialState })
            setDetailesState(result);

          })
        } else {
          Swal.fire("خطا", "اطلاعات ناقص است", "error")
        }
        break;

      default:
        break;
    }




  }
  return (
    <table class="table  text-nowrap text-md-nowrap table-striped mg-b-0">
      <thead>
        <tr>
          <th className='col-md-4'>#</th>
          <th className='col-md-4'>عنوان</th>
          <th className='col-md-4'>مقدار</th>

        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            <div class="btn-icon-list">
              <button class="btn ripple btn-primary btn-icon" onClick={onSaveClick}><i class="fe fe-save"></i></button>

            </div>

          </th>
          <td> <input class="form-control" name="name" value={selectedDetail.name} onChange={handleChange} required="" /></td>
          <td> <input class="form-control" name="val" value={selectedDetail.val} onChange={handleChange} required="" /></td>

        </tr>
        {
          detailsState.map((item) => {
            return <tr>
              <th scope="row">
                <div className='btn-icon-list'>
                  {

                    selectedAction == "edit" && selectedDetail.id == item.id ? (<button class="btn ripple btn-secondary btn-icon" onClick={onCloseEditClick}><i class="fa fa-times"></i></button>) :
                      (<button class="btn ripple btn-primary btn-icon" onClick={(event) => onEditClick(event, item)}><i class="fe fe-edit"></i></button>)

                  }
                  <button class="btn ripple btn-danger btn-icon" onClick={(event) => onDeleteClick(event, item)}><i class="fe fe-trash"></i></button>
                </div>
              </th>
              <td> {item.name}</td>
              <td>{item.val}</td>
            </tr>

          })
        }



      </tbody>
    </table>
  )
}

export default DetailList