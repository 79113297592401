import React,{ useEffect, useState, useRef } from 'react'

function TextEditor({myState,close,save,name,type="text"}) {
    const myInput = useRef(null);
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <button type='button' className='btn btn-danger' onClick={(event) => close(event, name)}><i className='fa fa-times'></i></button>
            </div>{type!="textarea"? <input ref={myInput} name={name} className='form-control' defaultValue={myState[name]} />
            :
        <textarea ref={myInput} name={name} className='form-control' defaultValue={myState[name]}></textarea>
        }
            <div className="input-group-append">
                <button type='button' className='btn btn-info' onClick={(event)=>save(event,myInput)}><i className='fa fa-save'></i></button>
            </div>
        </div>
    )
}

export default TextEditor