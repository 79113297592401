import axios from "axios";
import * as appConsts from '../store/consts'
import $ from 'jquery'
class API {
    constructor(parameters) {
        
    }

    sendPostWithoutToken(url,data,funcSuccess,funcError=null){
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${appConsts.api_url}${url}`,
        headers: { 
          'Content-Type': 'application/json', 
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        funcSuccess(response.data);
      })
      .catch((error) => {
        if(funcError!=null){
          funcError(error);
      }
      });
    }
    sendPatchWithToken(url,data,funcSuccess,funcError=null){

      const token= localStorage.getItem("token")
      console.clear();
      console.log(token)
      if(!token)
        return false;
      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${appConsts.api_url}${url}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        funcSuccess(response.data);
      })
      .catch((error) => {
        if(funcError!=null){
          funcError(error);
      }
      });
    }
    sendPostWithToken(url,data,funcSuccess,funcError=null){

      const token= localStorage.getItem("token")
      console.clear();
      console.log(token)
      if(!token)
        return false;
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${appConsts.api_url}${url}`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}`
        },
        data : data
      };
      
      axios.request(config)
      .then((response) => {
        funcSuccess(response.data);
      })
      .catch((error) => {
        if(funcError!=null){
          funcError(error);
      }
      });
    }
    sendPostFileWithToken(url,file,funcSuccess,funcError=null){

      const token= localStorage.getItem("token")
      console.clear();
      console.log(token);
      if(!token)
        return false;


      const formData = new FormData();
      formData.append("file", file, file.name);
      //formData.append('file', file);
      console.log(file.name);
      console.log(formData);

// var form = new FormData();
// form.append("file", fileInput.files[0], "/C:/Users/Behzad/Desktop/images/546486_389.jpg");

var settings = {
  "url": `${appConsts.api_url}${url}`,
  "method": "POST",
  "timeout": 0,
  "headers": {
    "Authorization": `Bearer ${token}`
  },
  "processData": false,
  "mimeType": "multipart/form-data",
  "contentType": false,
  "data": formData
};

$.ajax(settings).done(function (response) {
  console.log(response);
  funcSuccess(response);
});


      // axios.post(`${appConsts.api_url}${url}`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     'Authorization': `Bearer ${token}`
      //   },
      // })
      // .then(response => {
      //   console.log('File uploaded successfully:', response.data);
      //   funcSuccess(response.data)
      // })
      // .catch(error => {
      //   console.error('Error uploading file:', error);
      // });
      // let config = {
      //   method: 'post',
      //   maxBodyLength: Infinity,
      //   url: `${appConsts.api_url}${url}`,
      //   headers: { 
      //     //...data.getHeaders(),
         
      //     'Content-Type': 'multipart/form-data',
      //       'Authorization': `Bearer ${token}`
          
          
      //   },
      //   data : formData
      // };
      
      // axios.request(config)
      // .then((response) => {
      //   console.log(JSON.stringify(response.data));
      // })
      // .catch((error) => {
      //   console.log(error);
      // });



   
    }
   async sendGETWithToken(url,funcSuccess,funcError=null){
        const token= localStorage.getItem("token")
        console.clear();
        console.log(token)
        if(!token)
          return false;
        axios.get(`${appConsts.api_url}${url}`,{
            headers: {
              Authorization: `Bearer ${token}`
             
            }
          })
              .then(response => {
              
              
                funcSuccess(response.data);
                  
              
              
              
              })
              .catch(error => {
            
                if(funcError!=null){
                    funcError(error);
                }
              });
      
    
    
    
            }
}

export default API