import React, { useState, useRef } from 'react'
import Utils from '../../../modules/utils'
import * as appConsts from '../../../store/consts'
import Wrapper from '../../../hoc/Wrapper/Wrapper';

function AttachSelectorItem({item,select}) {
    const utl = new Utils();
    const [videoPlayIconState, setVideoPlayIconState] = useState("play")
    const videoObj = useRef(null);
  
    const onPlayVideoClick = (event) => {
      event.preventDefault();
      if (videoObj.current.paused) {
        videoObj.current.play();
        setVideoPlayIconState("pause")
  
      }
      else {
  
        videoObj.current.pause();
        setVideoPlayIconState("play")
      }
    }
    let AttachBody = "";
    let AttachTools = <Wrapper>
      <a className="product-like-icon d-none" href="#"><i className="far fa-heart"></i></a>
      <span className="product-discount-label bg-primary"><a href={`${appConsts.api_url}/api/attachments/file/${item.id}`} download className='text-white'><i className="fa fa-download"></i></a></span>
  
    </Wrapper>;
    switch (utl.get_file_type(item.ext)) {
      case "image":
        AttachBody = <img className="pic-1" style={{ maxHeight: "200px", width: "auto" }} alt="محصول-تصویر-1" src={`${appConsts.api_url}/api/attachments/file/${item.id}`}></img>
  
        break;
      case "video":
        AttachBody = <video ref={videoObj} style={{ maxHeight: "200px", width: "auto" }} className="pic-1" alt="محصول-تصویر-1"
          src={`${appConsts.api_url}/api/attachments/file/${item.id}`}
          >
  
        </video>
        AttachTools = <Wrapper>
          <a className="product-like-icon" href="#" onClick={onPlayVideoClick}><i className={`fa fa-${videoPlayIconState}`}></i></a>
          <span className="product-discount-label bg-primary"><a href={`${appConsts.api_url}/api/attachments/file/${item.id}`} className='text-white' target='_blanl' download><i className="fa fa-download"></i></a></span>
  
        </Wrapper>
        break;
  
      default:
        AttachBody = <img className="pic-1" style={{ maxHeight: "200px", width: "auto" }} alt="محصول-تصویر-1" src='./assets/images/file.jpg'></img>
        AttachTools = <Wrapper>
          <span className="product-like-icon" href="#">{item.ext}</span>
          <span className="product-discount-label bg-primary"><a href={`${appConsts.api_url}/api/attachments/file/${item.id}`} download className='text-white'><i className="fa fa-download"></i></a></span>
        </Wrapper>
        break;
    }
  


  return (
    <div className="col-md-6 col-lg-6 col-xl-4 col-sm-6" key={item.id}>
                                    <div className="card custom-card">
                                        <div className="p-0 ht-100p">
                                            <div className="product-grid">
                                                <div className="product-image">
                                                    <a href="#" className="image">
                                                        {AttachBody}

                                                    </a>
                                                   {AttachTools}
                                                    <div className="product-link">
                                                        <a href="#" style={{width:"100%"}} onClick={(event)=>select(event,item)}>
                                                            <i className="fa fa-hand-o-down"></i>
                                                            <span>انتخاب</span>
                                                        </a>
                                                      
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                <h3 className="title" style={{whiteSpace: "nowrap",textOverflow: "ellipsis"}}><a href="#">{item.name}</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
  )
}

export default AttachSelectorItem